@import "./Themes";

.background{
    background-color: #ffffff;
}

/* Header */

.header-container{
    @include titleContainer();
}

h1{
    @include pageTitle();
}

/* Intro */

.opportunities-intro-container{
    @include pageIntro();
    background-image: url('../media/opportunities.jpg');
    background-size: 100% auto;
    @media screen and (min-width: 475px) {
        background-size: 100% auto;
    }
    .opportunities-overlay{
        @include pageIntroOverlay();
        .opportunities-intro{
            @include pageIntroText();
            h3{
                text-align: center;
                color: #ffffff;
                text-shadow: 1px 1px 1px rgb(0, 0, 0);
            }
        }
    }
}

/* Incentives */

.incentives-browser{
    display: none;
    @media screen and (min-width: 1000px) {
        display: table;
        width: 100%;
        max-width: 1200px;
        margin: 50px auto;
        text-align: center;
        overflow: auto;
    }
    div{
        position: relative;
        display: inline-table;
        vertical-align: top;
        width: 46%;
        box-sizing: border-box;
        padding: 30px;
        margin: 1%;
        @include boxShadow();
        i{
            color: #004d00;
        }
        .find-out{
            display: inline-block;
            padding: 10px;
            background: transparent;
            border: 2px solid #004d00;
            color: #004d00;
            transition: 1s;
            &:hover{
                cursor: pointer;
                -webkit-box-shadow: 5px 5px 5px -2px rgba(102,102,102,1);
                -moz-box-shadow: 5px 5px 5px -2px rgba(102,102,102,1);
                box-shadow: 5px 5px 5px -2px rgba(102,102,102,1);
            }
        }
    }
}

.incentives-browser > div:nth-child(1),
.incentives-browser > div:nth-child(2) {
    @media screen and (min-width: 1000px) {
        height: 870px;
    }
    @media screen and (min-width: 1026px) {
        height: 840px;
    }
    @media screen and (min-width: 1038px) {
        height: 800px;
    }
    @media screen and (min-width: 1100px) {
        height: 945px;
    }
    @media screen and (min-width: 1115px) {
        height: 905px;
    }
    @media screen and (min-width: 1128px) {
        height: 865px;
    }
}

.incentives-browser > div:nth-child(3),
.incentives-browser > div:nth-child(4) {
    @media screen and (min-width: 1000px) {
        height: 760px;
    }
    @media screen and (min-width: 1087px) {
        height: 725px;
    }
    @media screen and (min-width: 1126px) {
        height: 825px;
    }
    @media screen and (min-width: 1180px) {
        height: 785px;
    }
}

.fas,
.far{
    font-size: 34px;
}

/* Mobile view */

.incentives-mobile{
    max-width: 95%;
    margin: 25px auto;
    @media screen and (min-width: 1000px) {
        display: none;
    }
}

.accordion{
    padding: 0px;
    margin: 0px;
    background-color: #ffffff;
    .incentive-title{
        position: relative;
        background-color: #ffffff;
        margin: 0px;
        padding: 0px 20px;
        border-bottom: 1px solid #000000;
        transition: all 0.7s ease;
        &:hover{
            cursor: pointer;
            background-color: #f2f2f2;
            transition: 0.5s;
        }
        h3{
            display: inline-block;
        }
        .expand-icon{
            float: right;
        }
    }
    .incentive-info{
        opacity: 0;
        max-height: 0;
        overflow-y: hidden;
        text-align: center;
        transition: all 0.7s ease;
    }
}

.accordion.open .incentive-info{
    max-height: 1000px;
    opacity: 1;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #000000;
}

.find-out-accordion{
    color: #004d00;
    background-color: #ffffff;
    border: 2px solid #004d00;
    font-size: 16px;
    padding: 10px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    transition: 0.5s;
    &:hover{
        cursor: pointer;
        color: #ffffff;
        background-color: #004d00;
        -webkit-box-shadow: 5px 5px 5px -2px rgba(102,102,102,1);
        -moz-box-shadow: 5px 5px 5px -2px rgba(102,102,102,1);
        box-shadow: 5px 5px 5px -2px rgba(102,102,102,1);
        transition: 0.5s;
    }
}