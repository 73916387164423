@import "./Themes";

.background{
    background-color: #ffffff;
}

/* Header */

.header-container{
    @include titleContainer();
}

h1{
    @include pageTitle();
}

/* Intro */

.referrals-intro-container{
    @include pageIntro();
    background-image: url('../media/partner.jpg');
    background-size: 100% auto;
    @media screen and (min-width: 475px) {
        background-size: 100% auto;
    }
    .referrals-overlay{
        @include pageIntroOverlay();
        .referrals-intro{
            @include pageIntroText();
        }
    }
}

.referrals-text{
    box-sizing: border-box;
    display: block;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 25px;
    text-align: center;
    .referrals-header{
        font-size: 26px;
        @media screen and (min-width: 500px) {
          font-size: 28px;
        }
        @media screen and (min-width: 700px) {
          font-size: 32px;
        }
        @media screen and (min-width: 1000px) {
          font-size: 34px;
        }
        @media screen and (min-width: 1100px) {
          font-size: 36px;
        }
    }
    .advantages-container{
        .advantages{
            box-sizing: border-box;
            display: block;
            background-image: linear-gradient(to right, #004d00, #006600);
            color: #ffffff;
            padding: 15px;
            width: 70%;
            margin: 10px auto;
            @media screen and (min-width: 1000px) {
                display: inline-block;
                vertical-align: top;
                width: 30%;
                margin: 1%;
                padding: 10px;
            }
            i{
                font-size: 40px;
                margin: 5px;
                @media screen and (min-width: 1000px) {
                    font-size: 50px;
                }
            }
            p{
                color: #ffffff;
            }
        }
    }
}

/* Claims process */

.referral-process-container{
    height: auto;
    margin: 25px auto;
    @media screen and (min-width: 1000px) {
        position: relative;
        height: 1850px;
        max-width: 1000px;
        padding: 30px;
        box-sizing: border-box;
        margin: 40px auto;
        transform: none;
    }
    @media screen and (min-width: 1100px) {
        height: 2380px;
    }
    h2{
        text-align: center;
        margin-top: 0px;
    }
    .referrals-header{
        font-size: 26px;
        @media screen and (min-width: 500px) {
          font-size: 28px;
        }
        @media screen and (min-width: 700px) {
          font-size: 32px;
        }
        @media screen and (min-width: 1000px) {
          font-size: 34px;
        }
        @media screen and (min-width: 1100px) {
          font-size: 36px;
        }
    }
    .link{
        display: none;
        @media screen and (min-width: 1000px) {
            position: absolute;
            display: block;
            width: 500px;
            left: 25%;
            border-radius: 20%;
            border-bottom: 8px solid #004d00;
            z-index: 100;
        }
    }
    .one-two{
        @media screen and (min-width: 1000px) {
            top: 245px;
            height: 500px;
            border-left: 8px solid #004d00;
            border-bottom-right-radius: 0%;
            border-top-left-radius: 0%;
        }
        @media screen and (min-width: 1100px) {
            top: 400px;
        }
    }
    .two-three{
        @media screen and (min-width: 1000px) {
            top: 580px;
            height: 480px;
            border-right: 8px solid #004d00;
            border-bottom-left-radius: 0%;
            border-top-right-radius: 0%;
        }
        @media screen and (min-width: 1100px) {
            top: 790px;
            height: 500px;
        }
    }
    .three-four{
        @media screen and (min-width: 1000px) {
            top: 930px;
            height: 500px;
            border-left: 8px solid #004d00;
            border-bottom-right-radius: 0%;
            border-top-left-radius: 0%;
        }
        @media screen and (min-width: 1100px) {
            top: 1240px;
        }
    }
    .four-five{
        @media screen and (min-width: 1000px) {
            top: 1305px;
            height: 500px;
            border-right: 8px solid #004d00;
            border-bottom-left-radius: 0%;
            border-top-right-radius: 0%;
        }
        @media screen and (min-width: 1100px) {
            top: 1690px;
        }
    }
    .material-icons{
        display: block;
        color: #004d00;
        width: 60px;
        transform: rotate(90deg);
        margin: auto;
        margin-bottom: 10px;
        @media screen and (min-width: 1000px) {
            display: none;
        }
    }
    .step{
        position: relative;
        background-color: #f2f2f2;
        top: 0px;
        padding: 20px;
        padding-top: 20px;
        margin: auto;
        text-align: left;
        max-width: 450px;
        z-index: 200;
        -webkit-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
        -moz-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
        box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
        @media screen and (min-width: 1000px) {
            position: absolute;
            padding-top: 0px;
        }
        .step-title{
            background-color: #004d00;
            color: #ffffff;
            width: 100%;
            padding-left: 10px;
            margin-top: -50px;
            margin-left: -5px;
            -webkit-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
            -moz-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
            box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
        }
    }
    .one{
        top: 0px;
        left: 0px;
        @media screen and (min-width: 1000px) {
            top: 135px;
        }
    }
    .two{
        @media screen and (min-width: 1000px) {
            top: 590px;
            right: 0px;
        }
        @media screen and (min-width: 1100px) {
            top: 715px;
        }
    }
    .three{
        @media screen and (min-width: 1000px) {
            top: 925px;
            left: 0px;
        }
        @media screen and (min-width: 1100px) {
            top: 1115px;
        }
    }
    .four{
        @media screen and (min-width: 1000px) {
            top: 1225px;
            right: 0px;
        }
        @media screen and (min-width: 1100px) {
            top: 1485px;
        }
    }   
    .five{
        @media screen and (min-width: 1000px) {
            top: 1665px;
            left: 0px;
        }
        @media screen and (min-width: 1100px) {
            top: 2010px;
        }
    }
}

.md-60{
    font-size: 60px;
}