h1,h2,h3,h4,h5,p,li,,th,td,button{
  font-family: 'Baloo Tamma 2', cursive;
}

h2{
  font-size: 18px;
  @media screen and (min-width: 500px) {
    font-size: 24px;
  }
  @media screen and (min-width: 700px) {
    font-size: 26px;
  }
  @media screen and (min-width: 1000px) {
    font-size: 28px;
  }
  @media screen and (min-width: 1100px) {
    font-size: 30px;
  }
}

h3{
  font-size: 16px;
  @media screen and (min-width: 500px) {
    font-size: 20px;
  }
  @media screen and (min-width: 700px) {
    font-size: 22px;
  }
  @media screen and (min-width: 1000px) {
    font-size: 24px;
  }
  @media screen and (min-width: 1100px) {
    font-size: 26px;
  }
}

p,li,th,td,button{
  font-size: 14px;
  @media screen and (min-width: 500px) {
    font-size: 16px;
  }
  @media screen and (min-width: 700px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1000px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1100px) {
    font-size: 22px;
  }
}

body{
  overflow-x: hidden;
}