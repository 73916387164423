@import "./Themes";

.contact-banner{
    display: none;
    @media screen and (min-width: 1100px) {
        display: block;
        height: 35px;
        width: 100vw;
        position: fixed;
        z-index: 1000;
        background: linear-gradient(to right, #404040, #666666);
        border-bottom: 5px solid #004d00;
        i{
            font-size: 18px;
            margin: 5px 10px 5px 20px;
        }
        p{
            color: #ffffff;
            font-size: 18px;
            float: right;
            margin: 5px 30px 5px 0px;
        }
        .email{
            display: inline;
        }
    }
}

.navbar{
    height: 60px;
    width: 100vw;
    position: fixed;
    background: #ffffff;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    z-index: 2000;
    transition: 1s;
    @media screen and (min-width: 700px) {
        height: 80px;
    }
    @media screen and (min-width: 1100px) {
        margin-top: 40px;
        background: transparent;
        background-color: rgba(255,255,255,0.6);
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    .navbar-logo{
        display: none;
        @media screen and (min-width: 1100px) {
            margin: 5px;
            height: 85%;
            display: inline-block;
            float: left;
        }
    }
    ul{
        @include navbarUl();
        li{
            display: none;
            @media screen and (min-width: 1100px) {
                display: inline-block;
                @include navbarLi();
            }
            .navbar-link{
                display: none;
                @media screen and (min-width: 1100px) {
                    display: inline-block;
                    @include navbarLink();
                }
                &:hover{
                    cursor: pointer;
                    color: #004d00;
                    transition: 0.5s;
                }
            }
        }
    }
}

.scrolled{
    background: #ffffff;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    transition: 1s;
}
/*
.menu-icon{
    display: inline-block;
    margin-right: 5px;
    @media screen and (min-width: 1100px) {
        display: none;
    }
}*/

/* Sidebar */

.menu-bars{
    color: #666666;
    margin: 40px 20px;
    @media screen and (min-width: 700px) {
        margin: 40px 20px;
    }
    @media screen and (min-width: 1100px) {
        display: none;
    }
    &:hover{
        cursor: pointer;
        color: #004d00;
    }
    .fa-bars{
        margin-top: 16px;
        font-size: 30px;
        transition: 1s;
        @media screen and (min-width: 700px) {
            margin-top: 25px;
            font-size: 34px;
            transition: 1s;
        }
    }
}

.fa-times{
    margin-left: -37px;
}

.nav-menu{
    background-color: #ffffff;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 0.5s;
    z-index: 2000;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    @media screen and (min-width: 1100px) {
        display: none;
    }
    .nav-menu-items{
        margin-top: 0px;
        width: 100%;
        @media screen and (min-width: 700px) {
            margin-top: 15px;
        }
        .navbar-toggle{
            background-color: #ffffff;
            width: 100%;
            height: 65px;
            display: flex;
            justify-content: start;
            align-items: center;
        }
    }
}

.nav-menu.active{
    left: 0;
    transition: 0.5s;
}

.nav-overlay{
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: #000000;
    opacity: 0.5;
    z-index: 1999;
    display: none;
}

.nav-overlay.active{
    display: block;
}

.nav-text{
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 8px;
    list-style: none;
    height: 30px;
}

.nav-text a {
    text-decoration: none;
    color: #666666;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 16px;
}

.nav-text a:hover{
    color: #004d00;
}

/* Scroll top button */

.scroll-top{
    width: 60px;
    height: 60px;
    color: #ffffff;
    background-color: #004d00;
    position: fixed;
    right: 0px;
    bottom: 10px;
    transition: 1s;
    opacity: 0.7;
    z-index: 2000;
    border: none;
    outline: none;
    display: none;
    &:hover{
        opacity: 1;
        cursor: pointer;
    }
}

.visible{
    display: block;
    transition: 1s;
}