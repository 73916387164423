@import "./Themes";

.partner-carousel-container{
    width: 100%;
    .partner-carousel{
        position: relative;
        width: 95vw;
        margin: auto;
        height: auto;
        box-sizing: border-box;
        padding: 0;
        display: flex;
        align-items: center;
        vertical-align: top;
        overflow-x: hidden;
        @media screen and (min-width: 900px) {
            width: 90%;
        }
        @media screen and (min-width: 1000px) {
            width: 80%;
        }
        .card{
            min-width: 100%;
            height: auto;
            margin: 0;
            transition: 0.5s;
            vertical-align: top;
            .inner-card{
                vertical-align: top;
                color: #666666;
                width: 90%;
                max-width: 800px;
                margin: auto;
                margin-bottom: 25px;
                @media screen and (min-width: 800px) {
                    width: 80%;
                }
                .small{
                    width: 45%;
                    @media screen and (min-width: 650px) {
                        width: 25%;
                    }
                }
                .medium{
                    width: 60%;
                    @media screen and (min-width: 650px) {
                        width: 45%;
                    }
                }
                .large{
                    width: 65%;
                    @media screen and (min-width: 650px) {
                        width: 50%;
                    }
                }
            }
        }
    }
}



.partner-logo{
    height: 40px;
    width: auto;
    margin: 10px;
    @media screen and (min-width: 900px) {
        height: 60px;
        margin: 15px;
    }
}