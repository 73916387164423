@import "./Themes";

.background{
    background-color: #ffffff;
}

.articles-container{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 70px 0px;
    @media screen and (min-width: 700px) {
        padding-top: 95px;
    }
    @media screen and (min-width: 1100px) {
        padding-top: 125px;
    }
    h1{
        margin: 15px 40px;
        @media screen and (min-width: 600px) {
            margin: 15px 50px;
        }
        @media screen and (min-width: 850px) {
            margin: 15px 65px;
        }
    }
    .article{
        width: 90%;
        padding: 10px 25px;
        box-sizing: border-box;
        margin: 20px auto;
        @include boxShadow();
        h2,p{
            color: #000000;
            margin: 0px;
        }
        p{
            font-style: italic;
        }
        .read-more{
            color: #004d00;
            text-align: right;
            text-decoration: underline;
            font-style: normal;
        }
    }
}