@import "./Themes";

.background{
    background-color: #ffffff;
}

/* Header */

.header-container{
    @include titleContainer();
}

h1{
    @include pageTitle();
}

/* Intro */

.explained-intro-container{
    @include pageIntro();
    background-image: url('../media/explained.jpg');
    background-size: 100% auto;
    @media screen and (min-width: 475px) {
        background-size: 100% auto;
    }
    .explained-overlay{
        @include pageIntroOverlay();
        .explained-intro{
            @include pageIntroText();
            h3{
                text-align: center;
                color: #ffffff;
                text-shadow: 1px 1px 1px rgb(0, 0, 0);
            }
        }
    }
}

/* Article body */

.explained-container{
    display: block;
    max-width: 1200px;
    margin: auto;
    height: auto;
    box-sizing: border-box;
    padding: 30px;
    overflow: auto;
    h2{
        font-weight: 500;
        background-image: linear-gradient(to right, #004d00, #006600);
        color: #ffffff;
        padding: 10px;
    }
}

/* Table */

table{
    max-width: 800px;
    margin: 30px auto;
}

th,td{
    background-color: #f2f2f2;
    padding: 10px;
}

/* CTA */

.explained-cta{
    display: block;
    background-image: url('../media/explained.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    opacity: 0.8;
    height: auto;
    width: 100vw;
    margin: auto;
    margin-top: 0px;
    overflow: hidden;
    @media screen and (min-width: 650px) {
        background-size: 100% auto;
    }
    @media screen and (min-width: 900px) {
        background-attachment: fixed;
    }
    .explained-overlay{
        height: 100%;
        width: 100%;
        background-color: rgba(102, 102, 102, 0.5);
        .cta-textbox{
            display: block;
            max-width: 1200px;
            margin: auto;
            height: auto;
            box-sizing: border-box;
            padding: 30px;
            overflow: auto;
            text-align: center;
            h2{
                font-weight: 500;
                color: #ffffff;
                text-shadow: 1px 1px 1px rgb(0, 0, 0);
                padding: 10px 0px;
            }
            p{
                color: #ffffff;
                text-shadow: 1px 1px 1px rgb(0, 0, 0);
            }
        }
    }
}

/* Consultation button */

.button-link{
    text-decoration: none;
    .consultation{
        @include consultationButton();
    }
}