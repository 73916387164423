@import "./Themes";

.background{
    background-color: #ffffff;
}

/* Header */

.header-container{
    @include titleContainer();
}

h1{
    @include pageTitle();
}

.intro-container{
    display: block;
    width: 100vw;
    max-width: 1200px;
    height: auto;
    box-sizing: border-box;
    overflow: hidden;
    margin: 20px auto;
    .contact-intro{
        display: block;
        position: relative;
        width: 100%;
        padding: 20px 40px;
        text-align: center;
        box-sizing: border-box;
        @media screen and (min-width: 1000px) {
            margin-top: 0px;
            width: 50%;
            display: inline-block;
            position: relative;
            box-sizing: border-box;
            padding: 20px;
            transform: none;
            text-align: left;
            float: left;
        }
    }
    .form-container{
        display: block;
        width: 90%;
        max-width: 800px;
        height: auto;
        margin: 0px auto;
        margin-bottom: 30px;
        @media screen and (min-width: 1000px) {
            position: relative;
            display: inline-block;
            width: 45%;
            height: auto;
            margin-top: 40px;
            float: right;
            margin-right: 30px;
        }
        form{
            box-sizing: border-box;
            input,
            textarea{
                box-sizing: border-box;
                margin: 10px 0px;
                padding: 5px;
                font-family: 'Baloo Tamma 2', cursive;
                font-size: 18px;
                outline:none;
                border: none;
                border-bottom: 1px solid #666666;
                width: 100%;
                font-size: 16px;
                // CSS to remove IOS styling
                -webkit-appearance: none;
                border-radius: 0;
                @media screen and (min-width: 700px) {
                  font-size: 18px;
                }
                @media screen and (min-width: 1000px) {
                  font-size: 20px;
                }
                @media screen and (min-width: 1100px) {
                  font-size: 22px;
                }
            }
            .name{
                width: 49%;
                float: left;
            }
            .number{
                width: 49%;
                float: right;
            }
            textarea{
                height: 100px;
            }
            input[type=submit]{
                border: none;
                padding: 10px;
                cursor: pointer;
                color: #004d00;
                background-color: #ffffff;
                border: 2px solid #004d00;
                transition: 0.5s;
                // CSS to remove IOS styling
                -webkit-appearance: none;
                border-radius: 0;
                &:hover{
                    color: #ffffff;
                    background-color: #004d00;
                    transition: 0.5s;
                }
            }
        }
    }
}

.contact-details{
    display: block;
    box-sizing: border-box;
    width: 300px;
    padding: 15px;
    margin: auto;
    margin-top: 500px;
    margin-bottom: 80px;
    text-align: center;
    font-size: 20px;
    @media screen and (min-width: 700px) {
        margin-bottom: 50px;
    }
}