@import "./Themes";

body{
    background-color: #1a1a1a;
}

/* Banner */

.background-image{
    background-image: linear-gradient(90deg, rgba(247,247,247,1), rgba(255,255,255,1));
    @media screen and (min-width: 400px) {
        background-image: url('../media/home.jpg');
        background-repeat: round;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
    }
    .banner-header{
        height: 100vh;
        width: 100vw;
        margin: auto;
        overflow: hidden;
        .header-overlay{
            height: 100%;
            background-color: rgba(255,255,255,0.55);
            .banner-text {
                box-sizing: border-box;
                margin: auto;
                padding: 30px;
                width: 100%;
                max-width: 900px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #666666;
                background-color: rgba(255,255,255,0.6);
                text-align: center;
                animation-name: fadeIn;
                animation-duration: 2s;
                animation-timing-function: linear;
                .logo-header-sml{
                    display: block;
                    width: 60px;
                    height: auto;
                    margin: 0 auto;
                    @media screen and (min-width: 700px) {
                        display: none;
                    }
                }
                .logo-header-sml-text{
                    display: block;
                    @media screen and (min-width: 550px) {
                        display: none;
                    }
                }
                .logo-header-med-text{
                    display: none;
                    @media screen and (min-width: 550px) {
                        display: block;
                    }
                    @media screen and (min-width: 700px) {
                        display: none;
                    }
                }
                .logo-header-lrg{
                    display: none;
                    @media screen and (min-width: 700px) {
                        display: block;
                        width: 100%;
                        height: auto;
                        margin: 20px auto;
                    }
                }
                .tagline {
                    margin: 0 auto;
                    font-size: 24px;
                    @media screen and (min-width: 700px) {
                        font-size: 32px;
                    }
                    @media screen and (min-width: 1400px) {
                        font-size: 40px;
                    }
                }
                .intro-text{
                    display: none;
                    @media screen and (min-width: 700px) {
                        display: block;
                        font-size: 16px;
                        font-weight: 500;
                    }
                    @media screen and (min-width: 900px) {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Text */

h1,h2,h3,h4,h5,p{
    font-weight: 200;
}

.btn{
    padding: 20px;
    background: transparent;
    text-decoration: none;
    border: 2px solid #666666;
    color: #666666;
    font-size: 16px;
    -webkit-box-shadow: 5px 5px 5px -2px rgba(102,102,102,1);
    -moz-box-shadow: 5px 5px 5px -2px rgba(102,102,102,1);
    box-shadow: 5px 5px 5px -2px rgba(102,102,102,1);
    transition: 0.5s;
    outline: none;
    &:hover{
        background: #666666;
        color: #ffffff;
        cursor: pointer;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        transition: 0.5s;
    }
    @media screen and (min-width: 1000px) {
        padding: 25px;
        font-size: 20px;
    }
}

/* Page intros */

.intro-background{
    .intro-container{
        position: relative;
        display: block;
        box-sizing: border-box;
        padding: 30px;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        @media screen and (min-width: 1450px) {
            width: 85%;
            max-width: 1500px;
            //border-left: 5px solid #666666;
            //border-right: 5px solid #004d00;
        }
        .margin-green{
            display: none;
            @media screen and (min-width: 1800px) {
                display: inline;
                position: absolute;
                width: 37px;
                height: auto;
                right: 23px;
            }
        }
        .green-1{
            top: 150px;
        }
        .green-2{
            top: 500px;
        }
        .margin-grey{
            display: none;
            @media screen and (min-width: 1800px) {
                display: inline;
                position: absolute;
                width: 38px;
                height: auto;
                left: 23px;
            }
        }
        .grey-1{
            top: 200px;
        }
        .grey-2{
            top: 400px;
        }
    }
}

.intro-background:nth-child(odd){
    background-color: #ffffff;
    @media screen and (min-width: 600px) {
        background: linear-gradient(20deg, rgba(247,247,247,1) 10%, 0%, rgba(255,255,255,1) 100%);
    }
    .intro-container{ 
        @media screen and (min-width: 1450px) {
            background: linear-gradient(90deg, rgba(0,0,0,0) 2.6%, 0%, 
                                               rgba(102,102,102,1) 3%, 0%, 
                                               rgba(0,0,0,0) 97%, 0%,
                                               rgba(0,77,0,1) 97.4%, 0%,
                                               rgba(255,255,255,1) 100%);
        }
        .link-container{
            margin: 30px auto;
            .text-box{
                top: 0px;
                left: 0px;
                min-width: 475px;
                background-color: #f2f2f2;
                @media screen and (min-width: 1000px) {
                    border-left: 3px solid #004d00;
                }
            }
            .decoration{
                background-color: #ffffff;
            }
            .image-container{
                right: 0px;
            }
        }
    }
}

.intro-background:nth-child(even){
    background-color: #ffffff;
    @media screen and (min-width: 600px) {
        background: linear-gradient(20deg, #ffffff 10%, 0%, #f7f7f7 100%);
    }
    .intro-container{
        @media screen and (min-width: 1450px) {
            background: linear-gradient(90deg, rgba(0,0,0,0) 2.6%, 0%, 
                                               rgba(102,102,102,1) 3%, 0%, 
                                               rgba(0,0,0,0) 97%, 0%,
                                               rgba(0,77,0,1) 97.4%, 0%,
                                               rgba(247,247,247,1) 100%);
        }
        .link-container{
            margin: 30px auto;
            .text-box{
                top: 0px;
                right: 0px;
                min-width: 475px;
                background-color: #ffffff;
                @media screen and (min-width: 1000px) {
                    border-right: 3px solid #004d00;
                }
                .intro-link{
                    .intro-btn{
                        float: right;
                        background-color: white;
                        &:hover{
                            background-color: #004d00;
                        }
                    }
                }
            }
            .decoration{
                background-color: #f7f7f7;
            }
            .image-container{
                left: 0px;
            }
        }
    }
}

.link-container{
    @include homepageContainers();

    .text-box{
        display: none;
        h3{
            font-weight: 200;
            font-size: 20px;
        }
        @media screen and (min-width: 1000px) {
            position: absolute;
            display: inline-block;
            width: 50%;
            padding: 0px 25px 25px 25px;
            border-top: 3px solid #004d00;
            z-index: 1;
            @include boxShadow();
        }
    }

    .decoration{
        display: none;
        @media screen and (min-width: 1000px) {
            display: block;
            position: absolute;
            width: 50%;
            height: 50%;
            left: 25%;
            top: 25%;
            background-image: url("https://www.transparenttextures.com/patterns/worn-dots.png");
            /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
            z-index: 0;
        }
    }

    .image-container{
        position: relative;
        display: block;
        width: auto;
        text-align: center;
        z-index: 100;
        transform: translate(-0%, -10%);
        @media screen and (min-width: 850px) {
            transform: translate(-0%, -5%);
        }
        @media screen and (min-width: 1000px) {
            position: absolute;
            margin-top: 250px;
            display: inline-block;
            width: 60%;
            transform: none;
        }
        .intro-img{
            display: none;
            width: 100%;
            height: auto;
            object-fit: cover;
            transform: none;
            @include boxShadow();
            @include imageMobileWidth();
            @media screen and (min-width: 1000px) {
                display: block;
            }
        }
        .intro-img-background{
            display: block;
            opacity: 0.6;
            object-fit: cover;
            margin: auto;
            margin-top: 30px;
            background-repeat: round;
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            min-height: 250px;
            @include boxShadow();
            @include imageMobileWidth();
            @media screen and (min-width: 565px) {
                background-size: 100%;
            }
            @media screen and (min-width: 1000px) {
                display: none;
            }
        }
        .intro-img-overlay{
            box-sizing: border-box;
            padding: 10px 20px;
            height: 100%;
            min-height: 250px;
            background-color: rgba(102, 102, 102, 0.5);
            h2,h3,hr{
                text-align: left;
                color: #ffffff;
                text-shadow: 1px 1px 1px rgb(0, 0, 0);
            }
        }
    }
}

/* Page links */

.intro-link-mobile{
    position: relative;
    display: block;
    margin: 0px;
    text-align: center;
    text-decoration: none;
    @media screen and (min-width: 1000px) {
        display: none;
    }
    .intro-btn-mobile{
        color: #000000;
        background-color: transparent;
        border: none;
        border: 3px solid #004d00;
        padding: 15px;
        font-size: 20px;
        position: relative;
        outline: none;
        transition: 0.5s;
        @include imageMobileWidth();
        &:hover{
            cursor: pointer;
            transform: translateY(-3%);
            -webkit-box-shadow: 0px 3px 3px 3px rgba(211,211,211,1);
            -moz-box-shadow: 0px 3px 3px 3px rgba(211,211,211,1);
            box-shadow: 0px 3px 3px 3px rgba(211,211,211,1);
            transition: 0.5s;
        }
        span{
            &:after{
                content: '\00bb';
            }
        }
    }
}

.intro-link{
    display: none;
    @media screen and (min-width: 1000px) {
        display: block;
    }
    .intro-btn{
        @include introButtons();
    }
}

/* Clients */

.clients{
    //background-color: #666666;
    background-color: #ffffff;
    border-top: 5px solid #666666;
    .clients-container{
        position: relative;
        height: auto;
        text-align: center;
        box-sizing: border-box;
        padding: 30px;
        margin: auto;
        @media screen and (min-width: 900px) {
            padding: 50px;
        }
        h2{
            font-size: 40px;
            text-align: center;
            color: #ffffff;
            color: #666666;
            //text-shadow: 3px 3px 10px rgba(0,0,0,1);
        }
    }
}

/* Testimonials */

.testimonials{
    background-image: url('../media/testimonials.jpg');
    background-repeat: round;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    .overlay{
        background-color: rgba(102, 102, 102, 0.5);
        .testimonials-container{
            position: relative;
            max-width: 1300px;
            height: auto;
            box-sizing: border-box;
            padding: 50px 0px;
            margin: auto;
            .testimonials-text{
                color: #ffffff;
                text-align: center;
                h2{
                    font-size: 40px;
                    text-shadow: 3px 3px 10px rgba(0,0,0,1);
                }
            }
        }
    }
}

/* Consultation button */

.contact-link{
    text-decoration: none;
    .consultation{
        @include consultationButton();
    }
}