@import "./Themes";

.background{
    background-color: #ffffff;
}

/* Header */

.header-container{
    @include titleContainer();
}

h1{
    @include pageTitle();
}

/* Intro */

.claims-intro-container{
    @include pageIntro();
    background-image: url('../media/claims.jpg');
    background-size: 100% auto;
    @media screen and (min-width: 475px) {
        background-size: 100% auto;
    }
    .claims-overlay{
        @include pageIntroOverlay();
        .claims-intro{
            @include pageIntroText();
        }
    }
}

.claims-text{
    box-sizing: border-box;
    display: block;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0px 25px;
    text-align: center;
}

/* Claims process */

.container{
    height: auto;
    margin: 25px auto;
    @media screen and (min-width: 1000px) {
        position: relative;
        height: 2100px;
        max-width: 1000px;
        padding: 30px;
        box-sizing: border-box;
        margin: 40px auto;
        transform: none;
    }
    @media screen and (min-width: 1100px) {
        height: 2450px;
    }
}

.link{
    display: none;
    @media screen and (min-width: 1000px) {
        position: absolute;
        display: block;
        width: 500px;
        left: 25%;
        border-radius: 20%;
        border-bottom: 8px solid #004d00;
        z-index: 100;
    }
}

.one-two{
    @media screen and (min-width: 1000px) {
        top: 85px;
        height: 500px;
        border-left: 8px solid #004d00;
        border-bottom-right-radius: 0%;
        border-top-left-radius: 0%;
    }
    @media screen and (min-width: 1100px) {
        top: 180px;
    }
}

.two-three{
    @media screen and (min-width: 1000px) {
        top: 595px;
        height: 500px;
        border-right: 8px solid #004d00;
        border-bottom-left-radius: 0%;
        border-top-right-radius: 0%;
    }
    @media screen and (min-width: 1100px) {
        top: 780px;
    }
}

.three-four{
    @media screen and (min-width: 1000px) {
        top: 960px;
        height: 500px;
        border-left: 8px solid #004d00;
        border-bottom-right-radius: 0%;
        border-top-left-radius: 0%;
    }
    @media screen and (min-width: 1100px) {
        top: 1215px;
    }
}

.four-five{
    @media screen and (min-width: 1000px) {
        top: 1335px;
        height: 500px;
        border-right: 8px solid #004d00;
        border-bottom-left-radius: 0%;
        border-top-right-radius: 0%;
    }
    @media screen and (min-width: 1100px) {
        top: 1650px;
    }
}

.step{
    position: relative;
    background-color: #f2f2f2;
    top: 0px;
    padding: 20px;
    padding-top: 20px;
    margin: auto;
    text-align: left;
    max-width: 450px;
    z-index: 200;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    @media screen and (min-width: 1000px) {
        position: absolute;
        padding-top: 0px;
    }
}

.one{
    top: 0px;
    left: 0px;
}
        
.two{
    @media screen and (min-width: 1000px) {
        top: 325px;
        right: 0px;
    }
    @media screen and (min-width: 1100px) {
        top: 365px;
    }
}
        
.three{
    @media screen and (min-width: 1000px) {
        top: 870px;
        left: 0px;
    }
    @media screen and (min-width: 1100px) {
        top: 1010px;
    }
}
        
.four{
    @media screen and (min-width: 1000px) {
        top: 1320px;
        right: 0px;
    }
    @media screen and (min-width: 1100px) {
        top: 1555px;
    }
}
        
.five{
    @media screen and (min-width: 1000px) {
        top: 1615px;
        left: 0px;
    }
    @media screen and (min-width: 1100px) {
        top: 1880px;
    }
}

.step-title{
    background-color: #004d00;
    color: #ffffff;
    width: 100%;
    padding-left: 10px;
    margin-top: -50px;
    margin-left: -5px;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
    box-shadow: 0px 5px 10px 0px rgba(211,211,211,1);
}

.material-icons{
    display: block;
    color: #004d00;
    width: 60px;
    transform: rotate(90deg);
    margin: auto;
    margin-bottom: 10px;
    @media screen and (min-width: 1000px) {
        display: none;
    }
}

.md-60{
    font-size: 60px;
}