.footer{
    box-sizing: border-box;
    height: 260px;
    max-width: 100%;
    left: 0;
    bottom: 0;
    margin: 0 auto;
    background-color: #1a1a1a;
    padding: 25px 0px;
    text-align: center;
    .links{
        display: block;
        width: 80%;
        max-width: 550px;
        margin: 5px auto;
        text-align: center;
        @media screen and (min-width: 425px) {
            border-bottom: 1px solid #666666;
        }
        ul{
            list-style: none;
            margin: 0 auto;
            li{
                font-size: 14px;
                @media screen and (min-width: 900px) {
                    font-size: 16px;
                }
                text-decoration: none;
                display: inline;
                margin: 5px;
                .footer-link{
                    color: #666666;
                }
            }
        }
    }
    .footer-logo{
        display: none;
        @media screen and (min-width: 425px) {
            display: block;
            max-width: 200px;
            margin: 20px auto;
        }
    }
}